<template>
  <div class="job-alert">
    <CCard>
      <CCardBody>
        <h5 class="text-primary">Candidate Communication Center</h5>
        <div class="view">
          <div class="documentScrolling document-table wrapper">
            <table class="table">
              <thead>
                <tr>
                  <th
                    class="nowrap"
                    v-for="(data, index) in fields"
                    :key="'th' + index"
                    :class="{ 'sticky-col': !index }"
                  >
                    {{ data.label }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(prop, rowKey) in getItems" :key="rowKey">
                  <td
                    v-for="(data, columnKey) in prop"
                    :key="columnKey + 'col'"
                    :class="
                      columnKey === 'job_id' ? 'info-text sticky-col' : ''
                    "
                  >
                    <div v-if="checkHyperLink(columnKey)">
                      <a
                        href="javascript:void(0)"
                        @click="openModal(columnKey, data)"
                        >{{ data }}</a
                      >
                    </div>
                    <div v-else>{{ data }}</div>
                  </td>
                  <td class="actionIcons">
                    <i
                      class="fas fa-phone text-primary"
                      title="Make Call"
                      @click="openSkype(prop.whatsapp)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </CCardBody>
    </CCard>
    <EmailModal
      v-if="showDialog.email"
      :isShowPopup="showDialog.email"
      :email_id="this.contact"
      @emailModalCallBack="modalCallBack"
      @conversationModalCallBack="conversationModalCallBack"
    />
    <MessageModal
      v-if="showDialog.message"
      :isShowPopup="showDialog.message"
      :phone="this.contact"
      :modal="this.modal"
      @messageModalCallBack="modalCallBack"
    />
    <MessageModal
      v-if="showDialog.whatsapp"
      :isShowPopup="showDialog.whatsapp"
      :phone="this.contact"
      :modal="this.modal"
      @messageModalCallBack="modalCallBack"
    />
    <DeleteModal
      v-if="deleteModal.isShowPopup"
      :isShowPopup="deleteModal.isShowPopup"
      :delete_id="deleteModal.delete_id"
      :email_subject="deleteModal.subject"
      @deleteModalCallBack="deleteModalCallBack"
      @emailModalCallBack="modalCallBack"
    />
    <Modal
      :modalTitle="skypeModal.modalTitle"
      :modalColor="skypeModal.modalColor"
      :modalContent="skypeModal.modalContent"
      :isShowPopup="showDialog.phone"
      :buttons="skypeModal.buttons"
      :modalCallBack="skypeModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import TextInput from "@/components/reusable/Fields/TextInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import EmailModal from "@/containers/Communication/EmailModal";
import MessageModal from "@/containers/Communication/MessageModal";
import DeleteModal from "@/containers/Communication/DeleteModal";
import Modal from "@/components/reusable/Modal";
extend("required", { ...required, message: "This field is required" });
export default {
  name: "CandidateDetails",
  components: {
    TextInput,
    TextareaInput,
    EmailModal,
    MessageModal,
    DeleteModal,
    Modal
  },

  data() {
    return {
      showDialog: {
        email: false,
        phone: false,
        message: false,
        whatsapp: false,
      },
      contact: null,
      modal: null,
      phone: null,
      skypeModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "Please ensure you have Skype installed, to proceed with phone call!",
        buttons: ["Cancel", "Ok"],
      },
      fields: [
        { label: "Job ID" },
        { label: "Status" },
        { label: "Type" },
        { label: "Facility" },
        { label: "Location" },
        { label: "Candidate Name" },
        { label: "Speciality" },
        { label: "Sub Speciality" },
        { label: "Age/Gender" },
        { label: "Aging" },
        { label: "Mail" },
        { label: "Message" },
        { label: "Whatsapp" },
        { label: "Action" },
      ],
      getItems: [
        {
          job_id: "TFA12256",
          status: "ShortList",
          type: "Physician",
          facility: "Mayo clinic",
          location: "CBE,IND",
          candidate_name: "Jasiya Afreen",
          speciality: "Test Data",
          sub_speciality: "Test Data",
          age: "25/M",
          aging: "7 Days",
          email: "karthiwins@gmail.com",
          message: "+91-1234567890",
          whatsapp: "+91-1234567890",
        },
        {
          job_id: "TFA12256",
          status: "ShortList",
          type: "Physician",
          facility: "Mayo clinic",
          location: "CBE,IND",
          candidate_name: "Jasiya Afreen",
          speciality: "Test Data",
          sub_speciality: "Test Data",
          age: "25/M",
          aging: "7 Days",
          email: "karkilan1@gmail.com",
          message: "+918870852193",
          whatsapp: "+918870852193",
        },
        {
          job_id: "TFA12256",
          status: "ShortList",
          type: "Physician",
          facility: "Mayo clinic",
          location: "CBE,IND",
          candidate_name: "Jasiya Afreen",
          speciality: "Test Data",
          sub_speciality: "Test Data",
          age: "25/M",
          aging: "7 Days",
          email: "balaji.shan@gmail.com",
          message: "+919884045658",
          whatsapp: "+919884045658",
        },
      ],
      deleteModal: {
        isShowPopup: false,
        delete_id: null,
        subject: null,
      },
    };
  },
  methods: {
    checkHyperLink(columnKey) {
      return ["email", "message", "whatsapp"].includes(columnKey);
    },
    openModal(columnKey, data) {
      this.modal = columnKey;
      this.contact = data;
      this.showDialog[columnKey] = true;
    },
    conversationModalCallBack(delete_id, subject) {
      this.deleteModal.isShowPopup = true;
      this.deleteModal.delete_id = delete_id;
      this.deleteModal.subject = subject;
    },
    deleteModalCallBack() {
      this.deleteModal.isShowPopup = false;
      this.showDialog.email = true;
    },
    modalCallBack(columnKey) {
      this.showDialog[columnKey] = false;
    },
    openSkype(phone) {
      this.showDialog.phone = true;
      this.phone = phone;
    },
    skypeModalCallBack(action) {
      let confirm = "Ok";
      if (action === confirm) {
        let url = "skype:" + this.phone + "?call";
        const link = document.createElement("a");
        link.setAttribute("href", url);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.showDialog.phone = false;
    },
    skypeAction(phone) {

    },
  },
};
</script>
<style lang="scss" scoped>
.nowrap {
  white-space: nowrap;
}
.view {
  border: 1px solid #e3e3e3;
}
th {
  background-color: #fafafa;
}
td {
  color: #777777;
}

.view {
  width: 100%;
}

.wrapper {
  position: relative;
  overflow: auto;
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 0px;
}
.sticky-col > th {
  background-color: #fafafa;
}
.info-text {
  color: #2678ce;
  font-weight: 600;
  background-color: white;
}
.conversation {
  padding-bottom: 10px;
  font-size: 12px;
}
.actionIcons i {
  cursor: pointer;
  padding: 5px;
}
.px-4 {
  float: right;
}
</style>